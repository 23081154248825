import CASL from '@/utils/constants/casl'

export default [
  {
    path: 'outlet',
    name: 'outlet.list',
    meta: {
      title: 'Outlet List',
      action: 'read',
      subject: CASL.ACCESS.OUTLET
    },
    component: () => import('./list/OutletListPage.vue')
  },
  {
    path: 'outlet/add',
    name: 'outlet.add',
    meta: {
      title: 'Outlet Add',
      action: 'create',
      subject: CASL.ACCESS.OUTLET
    },
    component: () => import('./form/OutletFormPage.vue')
  },
  {
    path: 'outlet/:uuid',
    component: () => import('./detail/OutletDetailPage.vue'),
    children: [
      {
        path: '',
        redirect: {
          name: 'outlet.detail.general'
        }
      },
      {
        path: 'general-information',
        name: 'outlet.detail.general',
        meta: {
          title: 'Outlet General Information',
          subject: CASL.ACCESS.OUTLET
        },
        component: () => import('./detail/tabs/OutletGeneral.vue')
      },
      {
        path: 'location',
        name: 'outlet.detail.location',
        meta: {
          title: 'Outlet Location',
          subject: CASL.ACCESS.OUTLET
        },
        component: () => import('./detail/tabs/OutletLocation.vue')
      },
      {
        path: 'contact-info',
        name: 'outlet.detail.contact',
        meta: {
          title: 'Outlet Contact Info',
          subject: CASL.ACCESS.OUTLET
        },
        component: () => import('./detail/tabs/OutletContact.vue')
      },
      {
        path: 'operating-hours',
        name: 'outlet.detail.hours',
        meta: {
          title: 'Outlet Operating Hours',
          subject: CASL.ACCESS.OUTLET
        },
        component: () => import('./detail/tabs/OutletHours.vue')
      }
    ]
  },
  {
    path: 'outlet/:uuid/general-information/edit',
    name: 'outlet.edit.general',
    meta: {
      title: 'Edit Outlet General Information',
      action: 'update',
      subject: CASL.ACCESS.OUTLET,
      tab: 'general'
    },
    component: () => import('./form/OutletFormPage.vue')
  },
  {
    path: 'outlet/:uuid/location/edit',
    name: 'outlet.edit.location',
    meta: {
      title: 'Edit Outlet Location',
      action: 'update',
      subject: CASL.ACCESS.OUTLET,
      tab: 'location'
    },
    component: () => import('./form/OutletFormPage.vue')
  },
  {
    path: 'outlet/:uuid/contact-info/edit',
    name: 'outlet.edit.contact',
    meta: {
      title: 'Edit Outlet Contact',
      action: 'update',
      subject: CASL.ACCESS.OUTLET,
      tab: 'contact'
    },
    component: () => import('./form/OutletFormPage.vue')
  },
  {
    path: 'outlet/:uuid/operating-hours/edit',
    name: 'outlet.edit.hours',
    meta: {
      title: 'Edit Outlet Operating Hours',
      action: 'update',
      subject: CASL.ACCESS.OUTLET,
      tab: 'hour'
    },
    component: () => import('./form/OutletFormPage.vue')
  }
]
