import CASL from '@/utils/constants/casl'

export default [
  {
    path: 'tnc',
    name: 'app-content.tnc',
    meta: {
      title: 'Term & Condition',
      action: 'read',
      subject: CASL.ACCESS.TERM_CONDITION
    },
    component: () => import('./TermConditionPage.vue')
  }
]
