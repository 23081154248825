import CASL from '@/utils/constants/casl'

export default [
  {
    path: 'user-list',
    name: 'outlet-app.user-list',
    meta: {
      title: 'Outlet App User List',
      action: 'read',
      subject: CASL.ACCESS.USER_LIST
    },
    component: () => import('./list/OutletUserListPage.vue')
  },
  {
    path: 'user-list/add',
    name: 'outlet-app.user-list.add',
    meta: {
      title: 'Outlet App User List Add',
      action: 'create',
      subject: CASL.ACCESS.USER_LIST
    },
    component: () => import('./form/OutletUserFormPage.vue')
  },
  {
    path: 'user-list/:uuid',
    name: 'outlet-app.user-list.detail',
    meta: {
      title: 'Outlet App User List Detail',
      action: 'read',
      subject: CASL.ACCESS.USER_LIST
    },
    component: () => import('./detail/OutletUserDetailPage.vue')
  },
  {
    path: 'user-list/:uuid/edit',
    name: 'outlet-app.user-list.edit',
    meta: {
      title: 'Outlet App User List Edit',
      action: 'update',
      subject: CASL.ACCESS.USER_LIST
    },
    component: () => import('./form/OutletUserFormPage.vue')
  }
]
