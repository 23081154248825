import TransactionEarningPoint from '@/pages/loyalty/earn-point/transaction-earning-point/routes'
import WelcomeBonusPoint from '@/pages/loyalty/earn-point/welcome-bonus-point/routes'
import GraduationBonusPoint from '@/pages/loyalty/earn-point/graduation-bonus-point/routes'

import CASL from '@/utils/constants/casl'

export default {
  path: 'earn-point',
  children: [
    {
      path: '',
      name: 'loyalty.earn-point',
      meta: {
        title: 'Earn Point',
        action: 'read',
        subject: CASL.ACCESS.POINT
      },
      component: () => import('./EarnPointPage.vue')
    },
    TransactionEarningPoint,
    WelcomeBonusPoint,
    GraduationBonusPoint
  ]
}
