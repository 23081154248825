import CASL from '@/utils/constants/casl'

export default {
  path: 'gift-code',
  children: [
    {
      path: '',
      name: 'crm.gift-code',
      meta: {
        title: 'Gift Code List',
        action: 'read',
        subject: CASL.ACCESS.GIFT_CODE
      },
      component: () => import('./list/GiftCodeListPage.vue')
    },
    {
      path: 'add',
      name: 'crm.gift-code.add',
      meta: {
        title: 'Gift Code Add',
        action: 'create',
        subject: CASL.ACCESS.GIFT_CODE
      },
      component: () => import('./form/GiftCodeFormPage.vue')
    },
    {
      path: ':uuid',
      name: 'crm.gift-code.detail',
      meta: {
        title: 'Gift Code Detail',
        action: 'read',
        subject: CASL.ACCESS.GIFT_CODE
      },
      component: () => import('./detail/GiftCodeDetailPage.vue')
    },
    {
      path: ':uuid/edit',
      name: 'crm.gift-code.edit',
      meta: {
        title: 'Gift Code Edit',
        action: 'update',
        subject: CASL.ACCESS.GIFT_CODE
      },
      component: () => import('./form/GiftCodeFormPage.vue')
    }
  ]
}
