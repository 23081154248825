import CASL from '@/utils/constants/casl'

export default [
  {
    path: 'add-point',
    name: 'loyalty.add-point',
    meta: {
      title: 'Add Point',
      action: 'read',
      subject: CASL.ACCESS.POINT_ADD
    },
    component: () => import('./list/AddPointListPage.vue')
  },
  {
    path: 'add-point/add',
    name: 'loyalty.add-point.add',
    meta: {
      title: 'Add Point Member',
      action: 'create',
      subject: CASL.ACCESS.POINT_ADD
    },
    component: () => import('./form/AddPointFormPage.vue')
  }
]
