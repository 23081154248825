import CASL from '@/utils/constants/casl'

export default [
  {
    path: 'list',
    name: 'admin.list',
    meta: {
      title: 'Admin List',
      action: 'read',
      subject: CASL.ACCESS.ADMIN
    },
    component: () => import('./list/AdminListPage.vue')
  },
  {
    path: 'list/add',
    name: 'admin.add',
    meta: {
      title: 'Admin Add',
      action: 'create',
      subject: CASL.ACCESS.ADMIN
    },
    component: () => import('./form/AdminFormPage.vue')
  },
  {
    path: 'list/:uuid',
    name: 'admin.detail',
    meta: {
      title: 'Admin Detail',
      action: 'read',
      subject: CASL.ACCESS.ADMIN
    },
    component: () => import('./detail/AdminDetailPage.vue')
  },
  {
    path: 'list/:uuid/edit',
    name: 'admin.edit',
    meta: {
      title: 'Admin Edit',
      action: 'update',
      subject: CASL.ACCESS.ADMIN
    },
    component: () => import('./form/AdminFormPage.vue')
  }
]
