import CASL from '@/utils/constants/casl'

export default [
  {
    path: 'tier/:tierUuid/benefit',
    name: 'benefit.list',
    meta: {
      title: 'Benefit List',
      action: 'read',
      subject: CASL.ACCESS.BENEFIT
    },
    component: () => import('./list/BenefitListPage.vue')
  },
  {
    path: 'tier/:tierUuid/benefit/manual/add',
    name: 'benefit.manual.add',
    meta: {
      title: 'Benefit Add',
      action: 'create',
      subject: CASL.ACCESS.BENEFIT
    },
    component: () => import('./form/BenefitManualFormPage.vue')
  },
  {
    path: 'tier/:tierUuid/benefit/manual/:uuid',
    name: 'benefit.manual.edit',
    meta: {
      title: 'Benefit Edit',
      action: 'update',
      subject: CASL.ACCESS.BENEFIT
    },
    component: () => import('./form/BenefitManualFormPage.vue')
  },
  {
    path: 'tier/:tierUuid/benefit/point-multiplier/add',
    name: 'benefit.point-multiplier.add',
    meta: {
      title: 'Benefit Add',
      action: 'create',
      subject: CASL.ACCESS.BENEFIT
    },
    component: () => import('./form/BenefitPointMultiplierFormPage.vue')
  },
  {
    path: 'tier/:tierUuid/benefit/point-multiplier/:uuid',
    name: 'benefit.point-multiplier.edit',
    meta: {
      title: 'Benefit Edit',
      action: 'update',
      subject: CASL.ACCESS.BENEFIT
    },
    component: () => import('./form/BenefitPointMultiplierFormPage.vue')
  },
  {
    path: 'tier/:tierUuid/benefit/birthday-free-item/add',
    name: 'benefit.birthday-free-item.add',
    meta: {
      title: 'Benefit Add',
      action: 'create',
      subject: CASL.ACCESS.BENEFIT
    },
    component: () => import('./form/BenefitBirthdayFreeItemFormPage.vue')
  },
  {
    path: 'tier/:tierUuid/benefit/birthday-free-item/:uuid',
    name: 'benefit.birthday-free-item.edit',
    meta: {
      title: 'Benefit Edit',
      action: 'update',
      subject: CASL.ACCESS.BENEFIT
    },
    component: () => import('./form/BenefitBirthdayFreeItemFormPage.vue')
  },
  {
    path: 'tier/:tierUuid/benefit/birthday-discount/add',
    name: 'benefit.birthday-discount.add',
    meta: {
      title: 'Benefit Edit',
      action: 'create',
      subject: CASL.ACCESS.BENEFIT
    },
    component: () => import('./form/BenefitBirthdayDiscountFormPage.vue')
  },
  {
    path: 'tier/:tierUuid/benefit/birthday-discount/:uuid',
    name: 'benefit.birthday-discount.edit',
    meta: {
      title: 'Benefit Edit',
      action: 'update',
      subject: CASL.ACCESS.BENEFIT
    },
    component: () => import('./form/BenefitBirthdayDiscountFormPage.vue')
  },
  {
    path: 'tier/:tierUuid/benefit/birthday-bonus-point/add',
    name: 'benefit.birthday-bonus-point.add',
    meta: {
      title: 'Benefit Add',
      action: 'create',
      subject: CASL.ACCESS.BENEFIT
    },
    component: () => import('./form/BenefitBirthdayBonusPointFormPage.vue')
  },
  {
    path: 'tier/:tierUuid/benefit/birthday-bonus-point/:uuid',
    name: 'benefit.birthday-bonus-point.edit',
    meta: {
      title: 'Benefit Edit',
      action: 'update',
      subject: CASL.ACCESS.BENEFIT
    },
    component: () => import('./form/BenefitBirthdayBonusPointFormPage.vue')
  }
]
