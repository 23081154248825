import HomeBanner from '@/pages/app-content/home-banner/routes'
import NewsPromo from '@/pages/app-content/news-promo/routes'
import FAQ from '@/pages/app-content/faq/routes'
import PrivacyPolicy from '@/pages/app-content/privacy-policy/routes'
import TnC from '@/pages/app-content/tnc/routes'
import Contact from '@/pages/app-content/contact/routes'
import WebConfig from '@/pages/app-content/web-config/routes'

export default {
  path: 'app-content',
  component: () => import('@/components/layouts/ChildLayout.vue'),
  children: [
    ...HomeBanner,
    ...NewsPromo,
    ...FAQ,
    ...PrivacyPolicy,
    ...TnC,
    ...Contact,
    ...WebConfig
  ]
}
