import CASL from '@/utils/constants/casl'

export default [
  {
    path: 'home-banner',
    name: 'app-content.home-banner',
    meta: {
      title: 'Home Banner',
      action: 'read',
      subject: CASL.ACCESS.BANNER
    },
    component: () => import('./HomeBannerPage.vue')
  }
]
