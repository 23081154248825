import CASL from '@/utils/constants/casl'

export default {
  path: 'point-value',
  component: () => import('./PointValuePage.vue'),
  children: [
    {
      path: '',
      name: 'loyalty.point-value',
      meta: {
        title: 'Points Value',
        action: 'update',
        subject: CASL.ACCESS.POINT_VALUE
      },
      component: () => import('./tabs/PointValue.vue')
    },
    {
      path: 'log',
      name: 'loyalty.point-log',
      meta: {
        title: 'Points Log',
        action: 'update',
        subject: CASL.ACCESS.POINT_VALUE
      },
      component: () => import('./tabs/PointLog.vue')
    }
  ]
}
