import CASL from '@/utils/constants/casl'

export default [
  {
    path: 'member',
    name: 'member.list',
    meta: {
      title: 'Member List',
      action: 'read',
      subject: CASL.ACCESS.MEMBER
    },
    component: () => import('./list/MemberListPage.vue')
  },
  {
    path: 'member/add',
    name: 'member.add',
    meta: {
      title: 'Add Member',
      action: 'create',
      subject: CASL.ACCESS.MEMBER
    },
    component: () => import('./form/MemberFormPage.vue')
  },
  {
    path: 'member/edit/:uuid',
    name: 'member.edit',
    meta: {
      title: 'Edit Member',
      action: 'update',
      subject: CASL.ACCESS.MEMBER
    },
    component: () => import('./form/MemberFormPage.vue')
  },
  {
    path: 'member/:uuid',
    component: () => import('./detail/MemberDetailPage.vue'),
    children: [
      {
        path: '',
        redirect: {
          name: 'member.detail.account'
        }
      },
      {
        path: 'account',
        name: 'member.detail.account',
        meta: {
          title: 'Member Account',
          subject: CASL.ACCESS.MEMBER
        },
        component: () => import('./detail/tabs/account/MemberAccount.vue')
      },
      {
        path: 'point-history',
        name: 'member.detail.point-history',
        meta: {
          title: 'Member Point History',
          subject: CASL.ACCESS.MEMBER
        },
        component: () =>
          import('./detail/tabs/MemberPointHistory/MemberPointHistory.vue')
      },
      {
        path: 'point-expired',
        name: 'member.detail.point-expired',
        meta: {
          title: 'Member Point Expired',
          subject: CASL.ACCESS.MEMBER
        },
        component: () =>
          import('./detail/tabs/MemberPointExpired/MemberPointExpired.vue')
      },
      {
        path: 'transaction-history',
        name: 'member.detail.transaction-history',
        meta: {
          title: 'Member Transaction History',
          subject: CASL.ACCESS.MEMBER
        },
        component: () =>
          import(
            './detail/tabs/MemberTransactionHistory/MemberTransactionHistory.vue'
          )
      },
      {
        path: 'transaction-history/:transactionUuid',
        name: 'member.detail.transaction-history-detail',
        meta: {
          title: 'Member Transaction History Detail',
          subject: CASL.ACCESS.MEMBER
        },
        component: () =>
          import(
            './detail/tabs/MemberTransactionHistory/detail/MemberTransactionHistoryDetail.vue'
          )
      },
      {
        path: 'redeem-history',
        name: 'member.detail.redeem-history',
        meta: {
          title: 'Member Redeem History',
          subject: CASL.ACCESS.MEMBER
        },
        component: () => import('./detail/tabs/MemberRedeemHistory.vue')
      },
      // {
      //   path: 'renewal-history',
      //   name: 'member.detail.renewal-history',
      //   meta: {
      //     title: 'Member Renewal History',
      //     subject: CASL.ACCESS.MEMBER
      //   },
      //   component: () => import('./detail/tabs/MemberRenewalHistory.vue')
      // },
      {
        path: 'mission-history',
        name: 'member.detail.mission-history',
        meta: {
          title: 'Member Mission History',
          subject: CASL.ACCESS.MEMBER
        },
        component: () => import('./detail/tabs/MemberMissionHistory.vue')
      },
      {
        path: 'changes-log',
        name: 'member.detail.changes-log',
        meta: {
          title: 'Member Changes Log',
          subject: CASL.ACCESS.MEMBER
        },
        component: () =>
          import('./detail/tabs/MemberChangesLog/MemberChangesLog.vue')
      },
      {
        path: 'voucher-usage',
        name: 'member.detail.voucher-usage',
        meta: {
          title: 'Member Voucher Usage',
          subject: CASL.ACCESS.MEMBER
        },
        component: () =>
          import('./detail/tabs/MemberVoucherUsage/MemberVoucherUsage.vue')
      },
      {
        path: 'welcome-mission',
        name: 'member.detail.welcome-mission',
        meta: {
          title: 'Member Welcome Mission',
          subject: CASL.ACCESS.MEMBER
        },
        component: () =>
          import('./detail/tabs/MemberWelcomeMission/MemberWelcomeMission.vue')
      },
      {
        path: 'tactical-mission',
        name: 'member.detail.tactical-mission',
        meta: {
          title: 'Member Tactical Mission',
          subject: CASL.ACCESS.MEMBER
        },
        component: () =>
          import('./detail/tabs/tactical-mission/TacticalMissionList.vue')
      },
      {
        path: 'mission/:missionUuid',
        name: 'member.detail.mission-detail',
        meta: {
          title: 'Member Mission Detail',
          subject: CASL.ACCESS.MEMBER
        },
        component: () =>
          import('./detail/tabs/mission-detail/MissionDetail.vue')
      }
    ]
  }
]
