import CASL from '@/utils/constants/casl'

export default [
  {
    path: 'tier',
    name: 'tier.list',
    meta: {
      title: 'Membership Tier',
      action: 'read',
      subject: CASL.ACCESS.TIER
    },
    component: () => import('./list/TierListPage.vue')
  },
  {
    path: 'tier/add',
    name: 'tier.add',
    meta: {
      title: 'Tier Add',
      action: 'create',
      subject: CASL.ACCESS.TIER
    },
    component: () => import('./form-add/TierAddFormPage.vue')
  },
  {
    path: 'tier/:uuid',
    name: 'tier.edit',
    meta: {
      title: 'Tier Edit',
      action: 'update',
      subject: CASL.ACCESS.TIER
    },
    component: () => import('./form/TierFormPage.vue')
  },
  {
    path: 'tier/add/special',
    name: 'tier.add.special',
    meta: {
      title: 'Tier Add',
      action: 'create',
      subject: CASL.ACCESS.TIER,
      isSpecialTier: true
    },
    component: () => import('./form/TierFormPage.vue')
  }
]
