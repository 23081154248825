import CASL from '@/utils/constants/casl'

export default {
  path: 'reward',
  children: [
    {
      path: '',
      name: 'loyalty.reward',
      meta: {
        title: 'Reward List',
        action: 'read',
        subject: CASL.ACCESS.REWARD
      },
      component: () => import('./list/RewardListPage.vue')
    },
    {
      path: 'add',
      name: 'loyalty.reward.add',
      meta: {
        title: 'Reward Add',
        action: 'create',
        subject: CASL.ACCESS.REWARD
      },
      component: () => import('./form/RewardFormPage.vue')
    },
    {
      path: ':uuid',
      name: 'loyalty.reward.detail',
      meta: {
        title: 'Reward Detail',
        action: 'read',
        subject: CASL.ACCESS.REWARD
      },
      component: () => import('./detail/RewardDetailPage.vue')
    }
  ]
}
