import EarnPoint from '@/pages/loyalty/earn-point/routes'
import Reward from '@/pages/loyalty/reward/routes'
import FeaturedReward from '@/pages/loyalty/featured-reward/routes'
import ExternalReward from '@/pages/loyalty/external-reward/routes'
import PointBrand from '@/pages/loyalty/point-brand/routes'
import PointValue from '@/pages/loyalty/point-value/routes'
import Reinstatement from '@/pages/loyalty/reinstatement/routes'
import BurnVoucher from '@/pages/loyalty/burn-voucher/routes'
import AddPoint from '@/pages/loyalty/add-point/routes'
import DeductPoint from '@/pages/loyalty/deduct-point/routes'

export default {
  path: 'loyalty',
  component: () => import('@/components/layouts/ChildLayout.vue'),
  children: [
    EarnPoint,
    Reward,
    ...FeaturedReward,
    ExternalReward,
    PointBrand,
    PointValue,
    ...Reinstatement,
    ...BurnVoucher,
    ...AddPoint,
    ...DeductPoint
  ]
}
