import CASL from '@/utils/constants/casl'

export default [
  {
    path: 'faq',
    name: 'app-content.faq',
    meta: {
      title: 'FAQ Category',
      action: 'read',
      subject: CASL.ACCESS.FAQ
    },
    component: () => import('./category/list/FAQCategoryListPage.vue')
  },
  {
    path: 'faq/add',
    name: 'app-content.faq.add',
    meta: {
      title: 'FAQ Category Add',
      action: 'create',
      subject: CASL.ACCESS.FAQ
    },
    component: () => import('./category/form/FAQCategoryForm.vue')
  },
  {
    path: 'faq/:uuid/edit',
    name: 'app-content.faq.edit',
    meta: {
      title: 'FAQ Category Edit',
      action: 'update',
      subject: CASL.ACCESS.FAQ
    },
    component: () => import('./category/form/FAQCategoryForm.vue')
  },
  {
    path: 'faq/question/:id/:categoryCode/:categoryName',
    name: 'app-content.faq.question',
    meta: {
      title: 'FAQ Question',
      action: 'read',
      subject: CASL.ACCESS.FAQ
    },
    component: () => import('./question/list/FAQQuestionListPage.vue')
  },
  {
    path: 'faq/question/:id/add',
    name: 'app-content.faq.question.add',
    meta: {
      title: 'FAQ Question Add',
      action: 'create',
      subject: CASL.ACCESS.FAQ
    },
    component: () => import('./question/form/FAQQuestionFormPage.vue')
  },
  {
    path: 'faq/question/:id/:uuid/edit',
    name: 'app-content.faq.question.edit',
    meta: {
      title: 'FAQ Question Edit',
      action: 'update',
      subject: CASL.ACCESS.FAQ
    },
    component: () => import('./question/form/FAQQuestionFormPage.vue')
  }
]
