import CASL from '@/utils/constants/casl'

export default [
  {
    path: 'deduct-point',
    name: 'loyalty.deduct-point',
    meta: {
      title: 'Deduct Point',
      action: 'read',
      subject: CASL.ACCESS.POINT_DEDUCT
    },
    component: () => import('./list/DeductPointListPage.vue')
  },
  {
    path: 'deduct-point/add',
    name: 'loyalty.deduct-point.add',
    meta: {
      title: 'Deduct Point Member',
      action: 'create',
      subject: CASL.ACCESS.POINT_DEDUCT,
      isDeduct: true
    },
    component: () => import('../add-point/form/AddPointFormPage.vue')
  }
]
