import CASL from '@/utils/constants/casl'

export default {
  path: 'tactical-mission',
  children: [
    {
      path: '',
      name: 'crm.tactical-mission',
      meta: {
        title: 'Tactical Mission List',
        action: 'read',
        subject: CASL.ACCESS.TACTICAL_MISSION
      },
      component: () => import('./list/TacticalMissionListPage.vue')
    },
    {
      path: 'mission-creator',
      name: 'crm.tactical-mission.mission-creator',
      meta: {
        title: 'Mission Creator',
        action: 'create',
        subject: CASL.ACCESS.TACTICAL_MISSION,
        missionType: 'tactical'
      },
      component: () =>
        import(
          '@/pages/crm/welcome-mission/mission-creator/MissionCreatorPage.vue'
        )
    },
    {
      path: 'mission-creator/:uuid',
      name: 'crm.tactical-mission.mission-creator.detail',
      meta: {
        title: 'Mission Creator',
        action: 'update',
        subject: CASL.ACCESS.TACTICAL_MISSION
      },
      component: () =>
        import(
          '@/pages/crm/welcome-mission/mission-creator/MissionCreatorPage.vue'
        )
    },
    {
      path: 'add',
      name: 'crm.tactical-mission.add',
      meta: {
        title: 'Tactical Mission Add',
        action: 'create',
        subject: CASL.ACCESS.TACTICAL_MISSION,
        missionType: 'tactical'
      },
      component: () =>
        import('@/pages/crm/welcome-mission/form/WelcomeMissionFormPage.vue')
    },
    {
      path: ':uuid',
      component: () =>
        import(
          '@/pages/crm/welcome-mission/detail/WelcomeMissionDetailPage.vue'
        ),
      children: [
        {
          path: '',
          redirect: {
            name: 'crm.tactical-mission.detail'
          }
        },
        {
          path: 'detail',
          name: 'crm.tactical-mission.detail',
          meta: {
            action: 'read',
            title: 'Tactical Mission Detail',
            subject: CASL.ACCESS.TACTICAL_MISSION,
            missionType: 'tactical'
          },
          component: () =>
            import(
              '@/pages/crm/welcome-mission/detail/mission-detail/MissionDetail.vue'
            )
        },
        {
          path: 'task',
          name: 'crm.tactical-mission.task',
          meta: {
            action: 'read',
            title: 'Tactical Mission Detail',
            subject: CASL.ACCESS.TACTICAL_MISSION,
            missionType: 'tactical'
          },
          component: () =>
            import(
              '@/pages/crm/welcome-mission/detail/mission-detail/MissionTask.vue'
            )
        },
        {
          path: 'result',
          name: 'crm.tactical-mission.result',
          meta: {
            action: 'read',
            title: 'Tactical Mission Detail',
            subject: CASL.ACCESS.TACTICAL_MISSION,
            missionType: 'tactical'
          },
          component: () =>
            import(
              '@/pages/crm/welcome-mission/detail/mission-detail/result/MissionResult.vue'
            )
        }
      ]
    },
    {
      path: ':uuid/edit',
      name: 'crm.tactical-mission.edit',
      meta: {
        title: 'Tactical Mission Edit',
        action: 'update',
        subject: CASL.ACCESS.TACTICAL_MISSION,
        missionType: 'tactical'
      },
      component: () =>
        import('@/pages/crm/welcome-mission/form/WelcomeMissionFormPage.vue')
    },
    // // Mission Task
    {
      path: ':uuid/mission-task/add',
      name: 'crm.tactical-mission.mission-task.add',
      meta: {
        title: 'Mission Task Add',
        action: 'create',
        subject: CASL.ACCESS.TACTICAL_MISSION,
        missionType: 'tactical'
      },
      component: () =>
        import(
          '@/pages/crm/welcome-mission/mission-task/MissionTaskFormPage.vue'
        )
    },
    {
      path: ':uuid/mission-task/:missionTaskUuid',
      name: 'crm.tactical-mission.mission-task.edit',
      meta: {
        title: 'Mission Task Edit',
        action: 'update',
        subject: CASL.ACCESS.TACTICAL_MISSION,
        missionType: 'tactical'
      },
      component: () =>
        import(
          '@/pages/crm/welcome-mission/mission-task/MissionTaskFormPage.vue'
        )
    }
  ]
}
