import CASL from '@/utils/constants/casl'

export default {
  path: 'graduation-bonus-point',
  name: 'loyalty.graduation-bonus-point',
  meta: {
    title: 'Welcome Bonus Point',
    action: 'read',
    subject: CASL.ACCESS.POINT
  },
  component: () => import('./form/GraduationBonusPointFormPage.vue')
}
