import CASL from '@/utils/constants/casl'

export default {
  path: 'welcome-mission',
  children: [
    {
      path: '',
      name: 'crm.welcome-mission',
      meta: {
        title: 'Welcome Mission List',
        action: 'read',
        subject: CASL.ACCESS.WELCOME_MISSION
      },
      component: () => import('./list/WelcomeMissionListPage.vue')
    },
    {
      path: 'mission-creator',
      name: 'crm.welcome-mission.mission-creator',
      meta: {
        title: 'Mission Creator',
        action: 'create',
        subject: CASL.ACCESS.WELCOME_MISSION,
        missionType: 'welcome'
      },
      component: () => import('./mission-creator/MissionCreatorPage.vue')
    },
    {
      path: 'mission-creator/:uuid',
      name: 'crm.welcome-mission.mission-creator.detail',
      meta: {
        title: 'Mission Creator',
        action: 'update',
        subject: CASL.ACCESS.WELCOME_MISSION,
        missionType: 'welcome'
      },
      component: () => import('./mission-creator/MissionCreatorPage.vue')
    },
    {
      path: 'add',
      name: 'crm.welcome-mission.add',
      meta: {
        title: 'Welcome Mission Add',
        action: 'create',
        subject: CASL.ACCESS.WELCOME_MISSION,
        missionType: 'welcome'
      },
      component: () => import('./form/WelcomeMissionFormPage.vue')
    },
    {
      path: ':uuid',
      component: () => import('./detail/WelcomeMissionDetailPage.vue'),
      children: [
        {
          path: '',
          redirect: {
            name: 'crm.welcome-mission.detail'
          }
        },
        {
          path: 'detail',
          name: 'crm.welcome-mission.detail',
          meta: {
            action: 'read',
            title: 'Welcome Mission Detail',
            subject: CASL.ACCESS.WELCOME_MISSION,
            missionType: 'welcome'
          },
          component: () => import('./detail/mission-detail/MissionDetail.vue')
        },
        {
          path: 'task',
          name: 'crm.welcome-mission.task',
          meta: {
            action: 'read',
            title: 'Welcome Mission Detail',
            subject: CASL.ACCESS.WELCOME_MISSION,
            missionType: 'welcome'
          },
          component: () => import('./detail/mission-detail/MissionTask.vue')
        },
        {
          path: 'result',
          name: 'crm.welcome-mission.result',
          meta: {
            action: 'read',
            title: 'Welcome Mission Detail',
            subject: CASL.ACCESS.WELCOME_MISSION,
            missionType: 'welcome'
          },
          component: () =>
            import(
              '@/pages/crm/welcome-mission/detail/mission-detail/result/MissionResult.vue'
            )
        }
      ]
    },
    {
      path: ':uuid/edit',
      name: 'crm.welcome-mission.edit',
      meta: {
        title: 'Welcome Mission Edit',
        action: 'update',
        subject: CASL.ACCESS.WELCOME_MISSION,
        missionType: 'welcome'
      },
      component: () => import('./form/WelcomeMissionFormPage.vue')
    },
    // Mission Task
    {
      path: ':uuid/mission-task/add',
      name: 'crm.welcome-mission.mission-task.add',
      meta: {
        title: 'Mission Task Add',
        action: 'create',
        subject: CASL.ACCESS.WELCOME_MISSION,
        missionType: 'welcome'
      },
      component: () => import('./mission-task/MissionTaskFormPage.vue')
    },
    {
      path: ':uuid/mission-task/:missionTaskUuid',
      name: 'crm.welcome-mission.mission-task.edit',
      meta: {
        title: 'Mission Task Edit',
        action: 'update',
        subject: CASL.ACCESS.WELCOME_MISSION,
        missionType: 'welcome'
      },
      component: () => import('./mission-task/MissionTaskFormPage.vue')
    }
  ]
}
