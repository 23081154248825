import CASL from '@/utils/constants/casl'

export default {
  path: 'voucher',
  children: [
    {
      path: '',
      name: 'library.voucher',
      meta: {
        title: 'Master Voucher List',
        action: 'read',
        subject: CASL.ACCESS.MASTER_VOUCHER
      },
      component: () => import('./list/VoucherListPage.vue')
    },
    {
      path: 'add',
      name: 'library.voucher.add',
      meta: {
        title: 'Master Voucher Add',
        action: 'create',
        subject: CASL.ACCESS.MASTER_VOUCHER
      },
      component: () => import('./form/VoucherFormPage.vue')
    },
    {
      path: ':uuid',
      component: () => import('./detail/VoucherDetailPage.vue'),
      children: [
        {
          path: '',
          redirect: {
            name: 'library.voucher.detail'
          }
        },
        {
          path: 'detail',
          name: 'library.voucher.detail',
          meta: {
            action: 'update',
            title: 'Master Voucher Detail',
            subject: CASL.ACCESS.MASTER_VOUCHER
          },
          component: () => import('./form/steps/FormVoucherDetail.vue')
        },
        {
          path: 'content',
          name: 'library.voucher.content',
          meta: {
            action: 'update',
            title: 'Master Voucher Detail',
            subject: CASL.ACCESS.MASTER_VOUCHER
          },
          component: () => import('./form/steps/FormVoucherContent.vue')
        }
      ]
    }
  ]
}
