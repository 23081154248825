import CASL from '@/utils/constants/casl'

export default [
  {
    path: 'referral-program',
    name: 'crm.referral-program',
    meta: {
      title: 'Referral Program',
      action: 'read',
      subject: CASL.ACCESS.REFERRAL_PROGRAM
    },
    component: () => import('./detail/ReferralDetailPage.vue')
  },
  {
    path: 'referral-program/edit',
    name: 'crm.referral-program.edit',
    meta: {
      title: 'Referral Program Edit',
      action: 'update',
      subject: CASL.ACCESS.REFERRAL_PROGRAM
    },
    component: () => import('./form/ReferralFormPage.vue')
  }
]
