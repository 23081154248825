import CASL from '@/utils/constants/casl'

export default {
  path: 'welcome-bonus-point',
  name: 'loyalty.welcome-bonus-point',
  meta: {
    title: 'Welcome Bonus Point',
    action: 'read',
    subject: CASL.ACCESS.POINT
  },
  component: () => import('./form/WelcomeBonusPointFormPage.vue')
}
