import CASL from '@/utils/constants/casl'

export default [
  {
    path: 'hybrid-outlet',
    name: 'hybrid-outlet.list',
    meta: {
      title: 'Hybrid Outlet List',
      action: 'read',
      subject: CASL.ACCESS.HYBRID_OUTLET
    },
    component: () => import('./list/HybridOutletListPage.vue')
  },
  {
    path: 'hybrid-outlet/add',
    name: 'hybrid-outlet.add',
    meta: {
      title: 'Hybrid Outlet Add',
      action: 'create',
      subject: CASL.ACCESS.HYBRID_OUTLET
    },
    component: () => import('./form/HybridOutletFormPage.vue')
  },
  {
    path: 'hybrid-outlet/:uuid/edit',
    name: 'hybrid-outlet.edit',
    meta: {
      title: 'Hybrid Outlet Edit',
      action: 'update',
      subject: CASL.ACCESS.HYBRID_OUTLET
    },
    component: () => import('./form/HybridOutletFormPage.vue')
  }
]
