import { createRouter, createWebHistory } from 'vue-router'

import Login from '@/pages/login/routes'
import Dashboard from '@/pages/dashboard/routes'
import Profile from '@/pages/profile/routes'
import Brand from '@/pages/brand/routes'
import Outlet from '@/pages/outlet/routes'
import HybridOutlet from '@/pages/hybrid-outlet/routes'
import Library from '@/pages/library/routes'
import Loyalty from '@/pages/loyalty/routes'
import Member from '@/pages/member/routes'
import Tier from '@/pages/tier/routes'
import Benefit from '@/pages/benefit/routes'
import CRM from '@/pages/crm/routes'
import AppContent from '@/pages/app-content/routes'
import Report from '@/pages/report/routes'
import OutletApp from '@/pages/outlet-app/routes'
import Admin from '@/pages/admin/routes'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/app',
      component: () => import('@/components/layouts/MainLayout.vue'),
      children: [
        ...Profile,
        Dashboard,
        ...Brand,
        ...Outlet,
        ...HybridOutlet,
        Library,
        Loyalty,
        ...Member,
        ...Tier,
        ...Benefit,
        CRM,
        AppContent,
        Report,
        OutletApp,
        Admin
      ]
    },
    {
      path: '/auth',
      component: () => import('@/components/layouts/AuthLayout.vue'),
      children: [...Login]
    },
    { path: '/:pathMatch(.*)*', redirect: '/app/member' }
  ]
})

router.beforeEach((to, from, next) => {
  // Get the page title from the route meta data that we have defined
  // See further down below for how we setup this data
  const title = to.meta.title
  // If the route has a title, set it as the page title of the document/page
  if (title) {
    const parentTitle = ' | Solva FnB CMS'

    document.title = (title + parentTitle) as any
  }
  // Continue resolving the route
  next()
})

export default router
