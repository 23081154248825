import { ElMessage } from 'element-plus'
import { WarningFilled, CircleCheckFilled } from '@element-plus/icons-vue'
import * as Sentry from '@sentry/browser'

type IType = 'success' | 'error'

export default () => {
  const showMessage = (type: IType, message: string) => {
    ElMessage({
      message: message,
      showClose: true,
      icon: type === 'error' ? WarningFilled : CircleCheckFilled,
      customClass: `${type === 'success' ? 'success-msg' : 'error-msg'}`
    })
  }

  /**
   * Retrieves the error message from an error object.
   * If the error object has a response, it returns the message from the response data.
   * If the response data message is an array, it returns the first element of the array.
   * If the error object does not have a response, it returns the error message itself.
   *
   * @param error - The error object.
   * @returns The error message.
   */
  const getErrorMessage = (error: any) => {
    if (error.response) {
      // The request was made and the server responded with a status code
      const errorMessage = error.response.data.message
      let message = error.response.data.message
      if (Array.isArray(errorMessage)) {
        message = error.response.data.message[0]
      }
      return message
    } else {
      // The request was made but no response was received

      // Send error to Sentry
      Sentry.captureException(error)

      return error.message
    }
  }

  return {
    showMessage,
    getErrorMessage
  }
}
