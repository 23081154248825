import CASL from '@/utils/constants/casl'

export default {
  path: 'external-reward',
  children: [
    {
      path: '',
      name: 'loyalty.external-reward',
      meta: {
        title: 'External Reward List',
        action: 'read',
        subject: CASL.ACCESS.EXTERNAL_REWARD
      },
      component: () => import('./list/ExternalRewardListPage.vue')
    },
    {
      path: 'add',
      name: 'loyalty.external-reward.add',
      meta: {
        title: 'External Reward Add',
        action: 'create',
        subject: CASL.ACCESS.EXTERNAL_REWARD
      },
      component: () => import('./form/ExternalRewardFormPage.vue')
    },
    {
      path: ':uuid',
      name: 'loyalty.external-reward.detail',
      meta: {
        title: 'External Reward Add',
        action: 'read',
        subject: CASL.ACCESS.EXTERNAL_REWARD
      },
      component: () => import('./detail/ExternalRewardDetailPage.vue')
    }
  ]
}
