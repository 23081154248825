import CASL from '@/utils/constants/casl'

export default {
  path: 'transaction-earning-point',
  children: [
    {
      path: '',
      name: 'loyalty.transaction-earning-point',
      meta: {
        title: 'Transaction Earning Point',
        action: 'read',
        subject: CASL.ACCESS.POINT
      },
      component: () => import('./list/TransactionEarningPointFormPage.vue')
    },
    {
      path: 'base-rule',
      name: 'loyalty.transaction-earning-point.base-rule.edit',
      meta: {
        title: 'Base Rule',
        action: 'update',
        subject: CASL.ACCESS.POINT
      },
      component: () => import('./form-base-rule/BaseRuleFormPage.vue')
    },
    {
      path: 'specific-rule',
      name: 'loyalty.transaction-earning-point.specific-rule.add',
      meta: {
        title: 'Specific Rule',
        action: 'create',
        subject: CASL.ACCESS.POINT
      },
      component: () => import('./form-specific-rule/SpecificRuleFormPage.vue')
    },
    {
      path: 'specific-rule/:uuid',
      name: 'loyalty.transaction-earning-point.specific-rule.edit',
      meta: {
        title: 'Base Rule',
        action: 'update',
        subject: CASL.ACCESS.POINT
      },
      component: () => import('./form-specific-rule/SpecificRuleFormPage.vue')
    }
  ]
}
