import CASL from '@/utils/constants/casl'

export default [
  {
    path: 'featured-reward',
    name: 'loyalty.featured-reward',
    meta: {
      title: 'Featured Reward',
      action: 'read',
      subject: CASL.ACCESS.FEATURED_REWARD
    },
    component: () => import('./list/FeaturedRewardListPage.vue')
  },
  {
    path: 'featured-reward/:order/add',
    name: 'loyalty.featured-reward.add',
    meta: {
      title: 'Featured Reward Add',
      action: 'create',
      subject: CASL.ACCESS.FEATURED_REWARD
    },
    component: () => import('./form/FeaturedRewardFormPage.vue')
  },
  {
    path: 'featured-reward/:uuid',
    name: 'loyalty.featured-reward.detail',
    meta: {
      title: 'Featured Reward Detail',
      action: 'read',
      subject: CASL.ACCESS.FEATURED_REWARD
    },
    component: () => import('./detail/FeaturedRewardDetailPage.vue')
  }
]
