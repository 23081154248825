export default [
  {
    path: 'login',
    name: 'login',
    meta: {
      title: 'Login'
    },
    component: () => import('./LoginPage.vue')
  }
]
