import CASL from '@/utils/constants/casl'

export default {
  path: 'export-report',
  name: 'report.export-report',
  meta: {
    title: 'Export Report',
    action: 'read',
    subject: CASL.ACCESS.EXPORT_REPORT
  },
  component: () => import('./ExportReportPage.vue')
}
