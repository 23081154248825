import CASL from '@/utils/constants/casl'

export default [
  {
    path: 'news-or-promo',
    name: 'app-content.news-or-promo',
    meta: {
      title: 'News or Promo List',
      action: 'read',
      subject: CASL.ACCESS.NEWS_PROMO
    },
    component: () => import('./list/NewsPromoListPage.vue')
  },
  {
    path: 'news-or-promo/add',
    name: 'app-content.news-or-promo.add',
    meta: {
      title: 'News or Promo Add',
      action: 'create',
      subject: CASL.ACCESS.NEWS_PROMO
    },
    component: () => import('./form/NewsPromoFormPage.vue')
  },
  {
    path: 'news-or-promo/:uuid',
    component: () => import('./detail/NewsPromoDetailPage.vue'),
    children: [
      {
        path: '',
        redirect: {
          name: 'app-content.news-or-promo.detail.content'
        }
      },
      {
        path: 'content',
        name: 'app-content.news-or-promo.detail.content',
        meta: {
          title: 'News or Promo Content Detail',
          subject: CASL.ACCESS.NEWS_PROMO
        },
        component: () => import('./detail/tabs/NewsContent.vue')
      },
      {
        path: 'cta',
        name: 'app-content.news-or-promo.detail.cta',
        meta: {
          title: 'News or Promo CTA Detail',
          subject: CASL.ACCESS.NEWS_PROMO
        },
        component: () => import('./detail/tabs/NewsCTA.vue')
      },
      {
        path: 'image',
        name: 'app-content.news-or-promo.detail.image',
        meta: {
          title: 'News or Promo Image Detail',
          subject: CASL.ACCESS.NEWS_PROMO
        },
        component: () => import('./detail/tabs/NewsImage.vue')
      }
    ]
  },
  {
    path: 'news-or-promo/:uuid/content/edit',
    name: 'app-content.news-or-promo.edit.content',
    meta: {
      title: 'News or Promo Content Edit',
      action: 'update',
      subject: CASL.ACCESS.NEWS_PROMO,
      tab: 'content'
    },
    component: () => import('./form/NewsPromoFormPage.vue')
  },
  {
    path: 'news-or-promo/:uuid/cta/edit',
    name: 'app-content.news-or-promo.edit.cta',
    meta: {
      title: 'News or Promo CTA Edit',
      action: 'update',
      subject: CASL.ACCESS.NEWS_PROMO,
      tab: 'cta'
    },
    component: () => import('./form/NewsPromoFormPage.vue')
  },
  {
    path: 'news-or-promo/:uuid/image/edit',
    name: 'app-content.news-or-promo.edit.image',
    meta: {
      title: 'News or Promo Image Edit',
      action: 'update',
      subject: CASL.ACCESS.NEWS_PROMO,
      tab: 'image'
    },
    component: () => import('./form/NewsPromoFormPage.vue')
  }
]
