import CASL from '@/utils/constants/casl'

export default [
  {
    path: 'web-config',
    name: 'app-content.web-config',
    meta: {
      title: 'Web Config',
      action: 'read',
      subject: CASL.ACCESS.WEB_CONFIG
    },
    component: () => import('./WebConfigPage.vue')
  }
]
