import CASL from '@/utils/constants/casl'

export default [
  {
    path: 'manual-burn-voucher',
    name: 'loyalty.manual-burn-voucher',
    meta: {
      title: 'Manual Burn Voucher',
      action: 'read',
      subject: CASL.ACCESS.BURN_VOUCHER
    },
    component: () => import('./list/BurnVoucherListPage.vue')
  },
  {
    path: 'manual-burn-voucher/form',
    name: 'loyalty.manual-burn-voucher.form',
    meta: {
      title: 'Manual Burn Voucher Form',
      action: 'update',
      subject: CASL.ACCESS.BURN_VOUCHER
    },
    component: () => import('./form/BurnVoucherFormPage.vue')
  }
]
