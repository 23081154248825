import CASL from '@/utils/constants/casl'

export default [
  {
    path: 'reinstatement',
    name: 'loyalty.reinstatement',
    meta: {
      title: 'Reinstatement',
      action: 'read',
      subject: CASL.ACCESS.REINSTATEMENT
    },
    component: () => import('./list/ReinstatementListPage.vue')
  },
  {
    path: 'reinstatement/:uuid',
    name: 'loyalty.reinstatement.detail',
    meta: {
      title: 'Reinstatement Detail',
      action: 'update',
      subject: CASL.ACCESS.REINSTATEMENT
    },
    component: () => import('./detail/ReinstatementDetailPage.vue')
  }
]
