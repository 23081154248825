import CASL from '@/utils/constants/casl'

export default [
  {
    path: 'preference',
    name: 'preference.list',
    meta: {
      title: 'Member Preferences List',
      action: 'read',
      subject: CASL.ACCESS.PREFERENCE
    },
    component: () => import('./list/PreferencesListPage.vue')
  }
]
