import MultiplierCampaign from '@/pages/crm/multiplier-campaign/routes'
import Referral from '@/pages/crm/referral/routes'
import GiftCode from '@/pages/crm/gift-code/routes'
import WelcomeMission from '@/pages/crm/welcome-mission/routes'
import TacticalMission from '@/pages/crm/tactical-mission/routes'

export default {
  path: 'crm',
  component: () => import('@/components/layouts/ChildLayout.vue'),
  children: [
    ...MultiplierCampaign,
    ...Referral,
    GiftCode,
    WelcomeMission,
    TacticalMission
  ]
}
