import CASL from '@/utils/constants/casl'

export default {
  path: 'engagement',
  component: () => import('./EngagementPage.vue'),
  children: [
    {
      path: '',
      redirect: {
        name: 'report.engagement.total-member'
      }
    },
    {
      path: 'total-member',
      name: 'report.engagement.total-member',
      meta: {
        title: 'Engagement Total Member',
        action: 'read',
        subject: CASL.ACCESS.REPORT_ENGAGEMENT
      },
      component: () => import('./tabs/total-member/TotalMember.vue')
    },
    {
      path: 'new-member',
      name: 'report.engagement.new-member',
      meta: {
        title: 'Engagement New Member',
        action: 'read',
        subject: CASL.ACCESS.REPORT_ENGAGEMENT
      },
      component: () => import('./tabs/new-member/NewMember.vue')
    },
    {
      path: 'active-member',
      name: 'report.engagement.active-member',
      meta: {
        title: 'Engagement Active Member',
        action: 'read',
        subject: CASL.ACCESS.REPORT_ENGAGEMENT
      },
      component: () => import('./tabs/active-member/ActiveMember.vue')
    },
    {
      path: 'point',
      name: 'report.engagement.point',
      meta: {
        title: 'Engagement Point',
        action: 'read',
        subject: CASL.ACCESS.REPORT_ENGAGEMENT
      },
      component: () => import('./tabs/point/ReportPoint.vue')
    },
    {
      path: 'member-transaction',
      name: 'report.engagement.member-transaction',
      meta: {
        title: 'Engagement Member Transaction',
        action: 'read',
        subject: CASL.ACCESS.REPORT_ENGAGEMENT
      },
      component: () => import('./tabs/transaction/ReportTransaction.vue')
    }
  ]
}
