import CASL from '@/utils/constants/casl'

export default {
  path: 'point-branding',
  name: 'loyalty.point-brand',
  meta: {
    title: 'Points Branding',
    action: 'update',
    subject: CASL.ACCESS.POINT_BRAND
  },
  component: () => import('./PointBrandPage.vue')
}
