export default [
  {
    path: 'my-profile',
    name: 'my-profile',
    meta: {
      title: 'My Profile',
      action: 'read'
    },
    component: () => import('./detail/ProfileDetailPage.vue')
  },
  {
    path: 'my-profile/edit',
    name: 'my-profile.edit',
    meta: {
      title: 'Edit Member',
      action: 'update'
    },
    component: () => import('./form/ProfileFormPage.vue')
  }
]
