import CASL from '@/utils/constants/casl'

export default [
  {
    path: 'multiplier-campaign',
    name: 'crm.multiplier-campaign',
    meta: {
      title: 'Multiplier Campaign List',
      action: 'read',
      subject: CASL.ACCESS.CAMPAIGN
    },
    component: () => import('./list/MultiplierCampaignListPage.vue')
  },
  {
    path: 'multiplier-campaign/add',
    name: 'crm.multiplier-campaign.add',
    meta: {
      title: 'Multiplier Campaign Add',
      action: 'create',
      subject: CASL.ACCESS.CAMPAIGN
    },
    component: () => import('./form/MultiplierCampaignFormPage.vue')
  },
  {
    path: 'multiplier-campaign/:uuid',
    name: 'crm.multiplier-campaign.detail',
    meta: {
      title: 'Multiplier Campaign Detail',
      action: 'read',
      subject: CASL.ACCESS.CAMPAIGN
    },
    component: () => import('./detail/MultiplierCampaignDetailPage.vue')
  },
  {
    path: 'multiplier-campaign/:uuid/edit',
    name: 'crm.multiplier-campaign.edit',
    meta: {
      title: 'Multiplier Campaign Edit',
      action: 'update',
      subject: CASL.ACCESS.CAMPAIGN
    },
    component: () => import('./form/MultiplierCampaignFormPage.vue')
  }
]
