import CASL from '@/utils/constants/casl'

export default {
  path: 'transaction-rating',
  name: 'report.transaction-rating',
  meta: {
    title: 'Transaction Rating',
    action: 'read',
    subject: CASL.ACCESS.REPORT_TRX_RATING
  },
  component: () => import('./TransactionRatingPage.vue')
}
